import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, Observable, retry, timer } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class HttpService {
  protected readonly http = inject(HttpClient);
  protected readonly baseUrl = environment.api.server;

  post<T>(url: string, body = {}, options = {}, host: string = null): Observable<T> {
    return this.http.post<T>(`${host || this.baseUrl}${url}`, body, options).pipe(
      retry({
        count: 3,
        delay: err => this.createErrorNotifier(err),
      })
    );
  }

  put<T>(url: string, body = {}, options = {}, host: string = null): Observable<T> {
    return this.http.put<T>(`${host || this.baseUrl}${url}`, body, options).pipe(
      retry({
        count: 3,
        delay: err => this.createErrorNotifier(err),
      })
    );
  }

  createErrorNotifier(err: { status: number }): Observable<0> {
    console.log(err);
    const isServerError = err.status === 504 || err.status === 503;
    return isServerError ? timer(1000) : EMPTY;
  }

  get<T>(url: string, options = {}, host: string = null): Observable<any> {
    return this.http.get<T>(`${host || this.baseUrl}${url}`, options).pipe(
      retry({
        count: 3,
        delay: err => this.createErrorNotifier(err),
      })
    );
  }

  delete(url: string, host: string = null) {
    return this.http.delete(`${host || this.baseUrl}${url}`, { responseType: 'text' }).pipe(
      retry({
        count: 3,
        delay: err => this.createErrorNotifier(err),
      })
    );
  }
}
